<template>
  <div>
    <Form inline :label-width="120" v-if="parentBusinessId && !businessType && !businessCode">
      <FormItem style="margin-bottom: 0">
        <span slot="label" class="validate">议事投票时限:</span>
        <DatePicker
          type="datetimerange"
          v-model="discussVoteTime"
          format="yyyy-MM-dd HH:mm"
          placeholder="请选择议事投票开始时间和结束时间"
          style="width: 350px"
          transfer
        ></DatePicker>
      </FormItem>
    </Form>
    <Divider style="margin: 16px 0" v-if="parentBusinessId && !businessType && !businessCode" />
    <div style="margin-bottom: 16px">
      <Button type="primary" icon="md-add" @click="addVoteOption" v-if="buttonRoot == '1001' && $route.query.startend != 'end'"
        >新增投票项</Button
      >
      <RadioGroup
        v-model="voteFormData.voteType"
        style="margin: 0 10px"
        @on-change="changeVoteType"
      >
        <Radio label="1" border :disabled="$route.query.startend == 'end' || buttonRoot != '1001'">投一票</Radio>
        <Radio label="2" border :disabled="$route.query.startend == 'end' || buttonRoot != '1001'">投多票</Radio>
      </RadioGroup>
      <span class="validate" v-if="voteFormData.voteType == '2'"
        >多选设置:</span
      >
      <span style="margin: 0 14px" v-if="voteFormData.voteType == '2'">
        最少选择
        <InputNumber
        :disabled="$route.query.startend == 'end' || buttonRoot != '1001'"
          :max="9999"
          :min="2"
          v-model="voteFormData.selectMinItem"
        ></InputNumber>
        个选项
      </span>
      <span v-if="voteFormData.voteType == '2'">
        最多选择
        <InputNumber
          :max="9999"
          :disabled="$route.query.startend == 'end' || buttonRoot != '1001'"
          :min="2"
          v-model="voteFormData.selectMaxItem"
        ></InputNumber>
        个选项
      </span>
      <Tooltip
        content="需填写大于等于2的自然数，两个数字可以相同"
        placement="top"
        transfer
        :max-width="500"
        v-if="voteFormData.voteType == '2'"
      >
        <Icon type="md-help-circle" />
      </Tooltip>
    </div>
    <LiefengTable
      :tableData="voteTableData"
      :talbeColumns="voteTalbeColumns"
      :hidePage="true"
    ></LiefengTable>
  </div>
</template>

<script>
import LiefengTable from '@/components/LiefengTable'
export default {
  props: ['parentBusinessId','businessType','businessCode','businessId','buttonRoot'],   //parentBusinessId存在——新增，  businessType,businessCode存在——更新
  data() {
    return {
      discussVoteTime: [],
      voteTableData: [
        {
          seq: 1,
          title: "",
          coverImg: "",
          loading: false,
        },
      ],
      rowIndex: "",
      voteTalbeColumns: [
        {
          title: "序号",
          key: "seq",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("InputNumber", {
              props: {
                max: 9999999999,
                min: 1,
                precision: 0,
                disabled: this.$route.query.startend == 'end' || this.buttonRoot != '1001',
                value: params.row.seq,
              },
              on: {
                "on-change": (val) => {
                  this.voteTableData[params.index].seq = val;
                },
              },
            });
          },
        },
        {
          title: "选项文字内容",
          key: "title",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("Input", {
              props: {
                maxlength: 50,
                value: params.row.title,
                disabled: this.$route.query.startend == 'end' || this.buttonRoot != '1001',
              },
              on: {
                "on-change": (e) => {
                  this.voteTableData[params.index].title = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "图片",
          key: "coverImg",
          align: "center",
          width: 200,
          render: (h, params) => {
            let result = "";
            if (this.voteTableData[params.index].coverImg) {
              result = h("img", {
                style: {
                  width: "150px",
                  display: "block",
                  margin: "10px",
                },
                attrs: {
                  src: this.voteTableData[params.index].coverImg,
                },
              });
            } else {
              result = h(
                "Upload",
                {
                  props: {
                    action: "",
                    name: "file",
                    disabled: this.$route.query.startend == 'end' || this.buttonRoot != '1001',
                    "before-upload": this.beforeUpload,
                    "show-upload-list": false,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        icon: "ios-cloud-upload-outline",
                        type: "primary",
                        loading: this.voteTableData[params.index].loading,
                      },
                      on: {
                        click: () => {
                          this.rowIndex = params.index;
                        },
                      },
                    },
                    this.voteTableData[params.index].loading
                      ? "正在上传"
                      : "上传图片"
                  ),
                ]
              );
            }
            return result;
          },
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: "ios-trash",
              },
              style: {
                fontSize: "20px",
                cursor: "pointer",
              },
              on: {
                click: () => {
                  if(this.$route.query.startend == 'end' || this.buttonRoot != '1001') return;
                  if (this.voteTableData.length == 1) {
                    this.$Message.error({
                      background: true,
                      content: "删除失败，请至少保留一个选项",
                    });
                    return;
                  }
                  this.voteTableData.splice(params.index, 1);
                },
              },
            });
          },
        },
      ],
      voteFormData: {
        voteType: "1",
      },
    };
  },
  methods: {
      save() {
      if ((this.discussVoteTime[0] == '' || this.discussVoteTime[1] == '') && this.parentBusinessId && !this.businessType && !this.businessCode) {
        this.$Message.error({
          background: true,
          content: "请选择议事投票开始时间和结束时间",
        });
        return;
      }
      if (
        this.voteFormData.voteType == "2" &&
        !this.voteFormData.selectMinItem
      ) {
        this.$Message.error({
          background: true,
          content: "请设置至少需要选择的选项",
        });
        return;
      }
      if (
        this.voteFormData.voteType == "2" &&
        !this.voteFormData.selectMaxItem
      ) {
        this.$Message.error({
          background: true,
          content: "请设置最多可以选择的选项",
        });
        return;
      }
      for (let k in this.voteTableData) {
        if (!this.voteTableData[k].seq) {
          this.$Message.error({
            background: true,
            content: "请设置第" + (Number(k) + 1) + "个选项的序号",
          });
          return;
        } else if (!this.voteTableData[k].title) {
          this.$Message.error({
            background: true,
            content: "请设置第" + (Number(k) + 1) + "个选项的选项文字内容",
          });
          return;
        }
      }
      this.$Message.loading({
        content: "正在保存，请稍等...",
        duration: 0,
      });

      let voteItemRos = this.voteTableData.map((item) => {
        return {
          ...item,
          loading: null
        };
      });
      let url = '';
      let params = {};
      if(this.parentBusinessId && !this.businessType && !this.businessCode) {
        url = "/sendmsg/pc/chat/discussBusiness/addNextVoteBusiness";
        params = {
           endTime: this.$core.formatDate(
            new Date(this.discussVoteTime[1]),
            "yyyy-MM-dd hh:mm:ss"
          ),
          startTime: this.$core.formatDate(
            new Date(this.discussVoteTime[0]),
            "yyyy-MM-dd hh:mm:ss"
          ),
          parentBusinessId: this.parentBusinessId,
        }
      }else {
        url = "/sendmsg/pc/chat/discussBusiness/updateVoteRule"
        params = {
          businessId: this.businessId
        }
      }
      this.$post(url,
        {
          ...params,
          ...this.voteFormData,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          modifier: parent.vue.loginInfo.userinfo.realName,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          voteItemRos: voteItemRos,
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          if (res.code == 200) {
            this.$Message.destroy();
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.$emit('saveNextSuccess');
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理",
          });
        });
    },
    // 新增投票项
    addVoteOption() {
      this.voteTableData.push({
        seq:
          this.voteTableData.length &&
          this.voteTableData[this.voteTableData.length - 1].seq
            ? this.voteTableData[this.voteTableData.length - 1].seq + 1
            : this.voteTableData.length + 1,
        title: "",
        coverImg: "",
        loading: false,
      });
    },
    // 切换投票方式单选按钮
    changeVoteType() {
      this.voteFormData.selectMinItem = null;
      this.voteFormData.selectMaxItem = null;
    },
    // 投票项上传图片——之前
    beforeUpload(file) {
      let suffix = file.name.substr(file.name.lastIndexOf("."));
      if (suffix != ".jpg" && suffix != ".png" && suffix != ".jpeg") {
        this.$Notice.warning({
          title: "上传文件格式错误！",
          desc:
            "文件 " + file.name + " 上传失败, 请上传jpg,png,jpeg格式的图片！",
        });
        return false;
      }
      //改变上传图片按钮——正在上传
      this.$set(this.voteTableData[this.rowIndex], "loading", true);

      this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng")
        .then((res) => {
          if (res.code == 200 && res.data) {
            let now = this.$core.randomString(8);
            let uploadImageData = {
              name: now + file.name,
              host: res.data.host,
              key: res.data.key + now + file.name,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
            let formData = new FormData();
            formData.append("name", uploadImageData.name);
            formData.append("host", uploadImageData.host);
            formData.append("key", uploadImageData.key);
            formData.append("policy", uploadImageData.policy);
            formData.append("OSSAccessKeyId", uploadImageData.OSSAccessKeyId);
            formData.append(
              "success_action_status",
              uploadImageData.success_action_status
            );
            formData.append("signature", uploadImageData.signature);
            formData.append("file", file);
            this.$.ajax({
              type: "POST",
              contentType: false,
              processData: false,
              url: uploadImageData.host,
              data: formData,
              headers:{openId:window.sessionStorage.getItem("openId"),Authorization:window.sessionStorage.getItem('accessToken')},
              success: () => {
                this.$set(
                  this.voteTableData[this.rowIndex],
                  "coverImg",
                  uploadImageData.host + uploadImageData.key
                );

                this.$Notice.success({
                  title: "上传成功",
                  desc: "文件 " + file.name + " 已成功上传",
                });
              },
              error: () => {
                this.$Notice.warning({
                  title: "上传失败！",
                  desc: "文件 " + file.name + " 上传失败！",
                });
              },
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "上传功能异常，请联系管理员处理",
          });
        });
      return false;
    },
  },
  created() {
    if(!this.parentBusinessId && this.businessType && this.businessCode) { //更新
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0
      })
      this.$get('/datamsg/api/pc/componentVote/getVoteInfo',{
        businessType: this.businessType,
        businessCode: this.businessCode
      }).then(res => {
        if(res.code == 200 && res.data) {
          this.voteFormData = {
           voteType: res.data.voteType,
           selectMaxItem: res.data.selectMaxItem,
           selectMinItem: res.data.selectMinItem,
          };
          this.voteTableData = res.data.voteItemVos.map(item => {
            return {
              ...item,
               loading: false,
            }
          })
          this.$Message.destroy();
        }else {
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        this.$Message.destroy();
        console.log(err);
        this.$Message.error({
          background: true,
          content: "获取数据失败，请联系管理员处理"
        })
      })
    }
  },
  components: {
    LiefengTable
  }
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-input-number-disabled,/deep/.ivu-input-number-disabled .ivu-input-number-input {
  background-color: #fff;
  color: #17233d
}
</style>